<template>
  <page-layout ref="layout">
    <template #breadcrumbs>
      <b-breadcrumb-item text="Management" />
      <b-breadcrumb-item text="Ensembles" :to="{ name: 'management-ensembles' }" />
      <b-breadcrumb-item :text="ensemble ? ensemble.name : 'Ensemble'" active />
    </template>

    <template #actions="{ state }">
      <can do="update" on="management-ensemble">
        <b-button v-if="state.editing" v-b-tooltip="'Update'" variant="transparent" size="sm" @click="updateEnsemble">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="management-ensemble">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item @click="updateState">
          <font-awesome-icon :icon="['fas', ensemble.state.enabled ? 'toggle-off' : 'toggle-on']"/>
          <span class="align-middle ml-50">{{ ensemble.state.enabled ? 'Disable' : 'Enable' }}</span>
        </b-dropdown-item>
        <can do="delete" on="management-ensemble">
          <b-dropdown-item @click="$refs.layout.confirmDelete(ensemble, deleteEnsemble, cascadeConfirmDeleteOptions)">
            <feather-icon icon="Trash2Icon"/>
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </can>
        <b-dropdown-divider/>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <validation-observer ref="observer" tag="div">
        <form ref="form">
          <b-row>
            <b-col cols="8">
              <b-card>
                <b-row>
                  <b-col align-self="start" cols="auto">
                    <b-avatar v-if="hasCustomAvatar(ensemble.avatar)" v-b-modal.avatar-modal variant="primary" size="8em" badge-variant="white" :disabled="!state.editing">
                      <b-img fluid fluid-grow :src="ensemble.avatar.src" :alt="ensemble.avatar.name"></b-img>
                      <template #badge>
                        <b-icon :icon="enabledIcon(ensemble.state.enabled)" :variant="enabledIconVariant(ensemble.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" :disabled="!state.editing">
                      <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                      <template #badge>
                        <b-icon :icon="enabledIcon(ensemble.state.enabled)" :variant="enabledIconVariant(ensemble.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <avatar-modal v-if="state.editing"
                                  title="Ensemble Avatar"
                                  :avatar="ensemble.avatar"
                                  @update-avatar="updateAvatar"/>
                  </b-col>
                  <b-col align-self="center">
                    <validation-provider v-slot="validationContext" vid="name" name="Ensemble Name" rules="required">
                      <b-form-group label="Name" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                        <b-input v-model="ensemble.name" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col align-self="center" cols="4">
                    <validation-provider v-slot="validationContext" vid="code" name="Ensemble Code" rules="required">
                      <b-form-group label="Code" label-for="code-input" :invalid-feedback="validationContext.errors[0]">
                        <b-input v-model="ensemble.code" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col align-self="center" cols="4">
                    <validation-provider v-slot="validationContext" vid="chair" name="Chairperson" rules="required">
                      <b-form-group label="Chairperson" label-for="form-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                        <v-select id="form-input"
                                  v-model="ensemble.chair"
                                  :disabled="!state.editing"
                                  :loading="chairs.loading"
                                  label="name"
                                  :options="chairs.items.filter(item => item.ensembleIds.includes(ensemble.id))"
                                  :select-on-tab="true"
                                  :reduce="(option) => { return { id: option.id, name: option.name } }"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  placeholder="Chairperson"
                                  class="form-control-plaintext w-100"
                                  @input="() => { ensemble.chairId = ensemble.chair.id }">
                          <template #option="{ name }">
                            {{ name.first }} {{ name.last }}
                          </template>
                          <template #selected-option="{ name }">
                            {{ name.first }} {{ name.last }}
                          </template>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>

              <b-card body-class="pb-1">
                <b-card-title class="d-flex justify-content-between mb-1">
                  Associated Instruments
                  <template v-if="state.editing && ensemble.instruments.items.length > 0">
                    <b-button v-b-tooltip="'Remove All Associated Instruments'" size="sm" variant="link" class="pr-0" @click="removeAllInstruments">
                      <feather-icon icon="XIcon" class="align-text-top" />
                    </b-button>
                  </template>
                  <template v-if="state.editing && ensemble.instruments.items.length === 0">
                    <b-button v-b-tooltip="'Associate All Instruments'" size="sm" variant="link" class="pr-0" @click="addAllInstruments">
                      <feather-icon icon="PlusIcon" class="align-text-top" />
                    </b-button>
                  </template>

                </b-card-title>
                <validation-provider #default="{ errors }" name="Instrument" rules="">
                  <b-form-group label="" label-for="instrument" :state="errors.length > 0 ? false:null">
                    <v-select id="instruments"
                              v-model="ensemble.instruments.items"
                              multiple label="id"
                              :options="options.instruments.items.filter(option => !ensemble.instruments.items.some(item => item.id === option.id))"
                              :loading="options.instruments.loading"
                              :disabled="!state.editing"
                              :filter="searchInstruments"
                              :selectable="(option) => !ensemble.instruments.items.includes(option.id)"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="ensemble-instruments h-100"
                              @option:selecting="createEnsembleInstrument"
                              @option:deselected="deleteEnsembleInstrument">
                      <template #option="{ instrument }">
                        {{ instrument.name }}
                      </template>
                      <template #selected-option="{ instrument }">
                        {{ instrument.name }}
                      </template>
                    </v-select>

                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-card>
            </b-col>
            <b-col>
              <b-card>
                <b-form inline class="my-1">
                  <b-form-checkbox v-model="ensemble.hasParts" :disabled="!state.editing" switch class="mr-1">
                    Has Parts?
                    <span class="sr-only">Switch for following text input</span>
                  </b-form-checkbox>

                  <b-form-checkbox v-model="ensemble.hasAlternates" :disabled="!state.editing" switch>
                    Has Alternates?
                    <span class="sr-only">Switch for following text input</span>
                  </b-form-checkbox>
                </b-form>

                <validation-provider v-slot="validationContext" vid="parts" name="Parts" rules="numeric">
                  <b-form-group label-for="parts" class="mb-1">
                    <b-input-group :class="[getValidationClass(validationContext), !state.editing || !ensemble.hasParts ? 'disabled' : null]">
                      <b-input-group-prepend is-text>
                        <small>Parts</small>
                      </b-input-group-prepend>
                      <b-input id="parts" v-model.number="ensemble.parts" type="number" :disabled="!state.editing || !ensemble.hasParts" :state="getValidationState(validationContext)"></b-input>
                    </b-input-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-card>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="4">
          <debug title="Ensemble">{{ ensemble }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Ensemble Instruments">{{ ensembleInstruments }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Instruments">{{ options.instruments }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>


<script>
import PageLayout from '@/components/PageLayout.vue';
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import slugify from 'slugify';
import vSelect from 'vue-select'
import avatar from '@/mixins/avatar.mixin'
import notify from '@/mixins/notify.mixin'
import status from '@/mixins/status.mixin'
import role from '@/mixins/role.mixin';
import print from '@/mixins/print.mixin';
import {listInstruments} from '@/graphql/queries';
import {uuid} from 'vue-uuid';
import Fuse from 'fuse.js';
import { API, graphqlOperation } from 'aws-amplify';
import { getEnsemble, getEnsembleBySlug, updateEnsemble, getUser } from '@/graphql/queries/ensemble'
import {
  createEnsembleInstruments,
  deleteEnsembleInstruments,
} from '@/graphql/mutations';
import { cascadeDeleteEnsemble, cascadeConfirmDeleteOptions} from '@/graphql/cascade/ensemble';
import {adminListUsersInGroup} from '@/scripts/aws';

export default {
  components: {
    PageLayout,
    AvatarModal,
    vSelect
  },
  mixins: [ avatar, role, status, print, notify ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ensemble: null,
      ensembleInstruments: {
        loading: true,
        items: []
      },
      chairs: {
        loading: true,
        items: []
      },
      options: {
        instruments: {
          loading: true,
          items: []
        },
      },
      icon: 'fa-people-line',
      cascadeConfirmDeleteOptions
    }
  },
  async mounted() {
    await this.listChairs();
    await this.getEnsemble();
    await this.listInstruments();
  },
  methods: {
    async listChairs() {
      await adminListUsersInGroup('NYSSMA_Chair').then(async (chairs) => {
        this.chairs.items = chairs.map((chair) => ({
          id: chair.Attributes.find(attr => attr.Name === 'custom:user_id')?.Value,
          username: chair.Username,
          name: { first: undefined, last: undefined },
          ensembleIds: [],
        }))
        await this.processChairsInChunks(this.chairs.items, 5, 1000);
        this.chairs.loading = false
      })
    },
    async getUserById(id) {
      const response = await API.graphql(graphqlOperation(getUser, { id }))
      return response.data.getUser;
    },

    async delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async processChairsInChunks(chairs, chunkSize, delayTime) {
      for (let i = 0; i < chairs.length; i += chunkSize) {
        const chunk = chairs.slice(i, i + chunkSize);
        // eslint-disable-next-line no-await-in-loop
        await Promise.all(chunk.map(async (chair) => {
          const user = await this.getUserById(chair.id);
          if(user) {
            chair.name = user.name;
            chair.address = user.address;
            chair.ensembleIds = user.ensembleIds;
          }
        }));

        if (i + chunkSize < chairs.length) {
          // eslint-disable-next-line no-await-in-loop
          await this.delay(delayTime);
        }
      }
    },

    async getEnsemble() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getEnsemble, { id: this.id }));
        this.ensemble = response.data.getEnsemble
        if(this.ensemble) {
          this.ensemble.instruments.items.sort((a, b) => a.instrument.name.localeCompare(b.instrument.name));
        }
      }
      else {
        const response = await API.graphql(graphqlOperation(getEnsembleBySlug, { slug: this.slug }));
        // eslint-disable-next-line prefer-destructuring
        this.ensemble = response.data.getEnsembleBySlug.items[0]
        if(this.ensemble) {
          this.ensemble.instruments.items.sort((a, b) => a.instrument.name.localeCompare(b.instrument.name));
        }
      }
      this.$refs.layout.state.loading = false
    },
    async patchEnsemble(input) {
      try {
        const response = await API.graphql(graphqlOperation(updateEnsemble, { input: input } ));
        //this.ensemble = response.data.updateEnsemble;
        this.notify({ title: 'Success', text: 'Ensemble was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'Ensemble failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async updateEnsemble() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchEnsemble({
          id: this.ensemble.id,
          name: this.ensemble.name,
          code: this.ensemble.code,
          parts: this.ensemble.parts,
          chairId: this.ensemble.chairId,
          hasParts: this.ensemble.hasParts,
          hasAlternates: this.ensemble.hasAlternates,
          slug: slugify(this.ensemble.name, { lower: true }),
        })
      }
      else {
        this.notify({ title: 'Warning', text: 'Ensemble failed to update. Missing required fields.', icon: this.icon, variant: 'warning'});
      }
    },
    async deleteEnsemble(ensemble, swalCallback) {
      try {
        await this.cascadeDeleteEnsemble(ensemble.id, swalCallback);
        await this.notify({ title: 'Success', text: 'Ensemble was successfully deleted', icon: this.icon, variant: 'success' });
        await this.$router.push({ name: 'management-ensembles' })
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Ensemble failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for swal
      }
    },
    cascadeDeleteEnsemble,

    // Ensemble Instruments
    async listInstruments() {
      this.options.instruments.loading = true
      const response = await API.graphql(graphqlOperation(listInstruments));
      /** Pull all instruments and map the output so that it fits the format of the ensemble instruments.
       *  If the ensemble already has instruments, find the ensemble instrument id that matches the instrument from listInstruments,
       *  otherwise create a new one, which will be used for both creating and deleting an ensemble instrument
       */
      this.options.instruments.items = response.data.listInstruments.items.map( instrument => ({
        id: this.ensemble.instruments.items.find(ei => ei.instrument.id === instrument.id)?.id || uuid.v4(),
        instrument: {
          id: instrument.id,
          name: instrument.name
        }
      })).sort((a, b) => a.instrument.name.localeCompare(b.instrument.name));
      this.options.instruments.loading = false
    },
    async createEnsembleInstrument(option) {
      const input = {
        id: option.id,
        ensembleID: this.ensemble.id,
        instrumentID: option.instrument.id
      }
      await API.graphql(graphqlOperation(createEnsembleInstruments, { input: input }));
    },
    async deleteEnsembleInstrument(option) {
      await API.graphql(graphqlOperation(deleteEnsembleInstruments, { input: { id: option.id } }));
    },
    addAllInstruments() {
      this.options.instruments.items.forEach(item => {
        this.createEnsembleInstrument(item)
      })
      this.ensemble.instruments.items.push(...this.options.instruments.items)
    },
    removeAllInstruments() {
      this.ensemble.instruments.items.forEach(item => {
        this.deleteEnsembleInstrument(item)
      })
      this.ensemble.instruments.items = []
    },
    searchInstruments(options, search) {
      const fuse = new Fuse(options, {
        keys: ['instrument.name'],
        shouldSort: true,
        threshold: 0.3
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },

    // eslint-disable-next-line no-shadow
    async updateAvatar(avatar) {
      this.ensemble.avatar = avatar;
      await this.patchEnsemble({ id: this.ensemble.id, avatar: this.ensemble.avatar })
    },
    async updateState() {
      this.ensemble.state.enabled = !this.ensemble.state.enabled;
      await this.patchEnsemble({ id: this.ensemble.id, state: this.ensemble.state })
    },



    async refresh() {
      this.$refs.layout.state.loading = true
      await this.getEnsemble()
      this.$refs.layout.state.loading = false
    },
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
  },
}
</script>

<style lang="scss">

.ensemble-instruments {
  margin-bottom: 2rem;
}

.form-control-plaintext {
  border: 0!important;
  border-radius: 0!important;
  -webkit-box-shadow: unset!important;
  box-shadow: unset!important;
  padding: 0!important;
  margin: 0!important;
  transition: unset!important;
  height: unset!important;
}

.form-control-plaintext[readonly] {
  background-color: unset!important;
  opacity: 1;
}

.form-control-plaintext:focus-visible {
  outline: 0;
}
.form-control-plaintext:focus {
  color: inherit;
  background-color: inherit;
  border: 0;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
</style>

