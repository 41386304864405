/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Ensemble may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Instruments and Selections associated with this ensemble will have their association removed.<br/>
            This may effect applications, scores, selections, acceptance, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Ensemble was successfully deleted.' }
}

export async function cascadeDeleteEnsemble(ensembleId, swalCallback) {
    try {
        /** Delete Ensemble-Instrument records associated to this Ensemble **/
        const ensembleInstruments = await listEnsembleInstruments(ensembleId, swalCallback)
        await batchProcessWithRetry(ensembleInstruments, async (ensembleInstrument) => {
            await API.graphql(graphqlOperation(deleteEnsembleInstrumentsMutation, { input: { id: ensembleInstrument.id }}));
        }, { title: 'Deleting Ensemble Instruments', callback: swalCallback })

        /** Update Selection records associated to this Ensemble **/
        const selections = await listSelections(ensembleId, swalCallback)
        await batchProcessWithRetry(selections, async (selection) => {
            await API.graphql(graphqlOperation(updateSelectionMutation, { input: { id: selection.id, selectionEnsembleId: null }}));
        }, { title: 'Updating Selections', callback: swalCallback })

        /** Delete Ensemble **/
        await deleteEnsemble(ensembleId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteEnsemble(ensembleId, swalCallback) {
    const title = 'Deleting Ensemble'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteEnsembleMutation, {input: { id: ensembleId }}));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}
async function listSelections(ensembleId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { selectionEnsembleId: { eq: ensembleId } } }
            const response = await API.graphql(graphqlOperation(listSelectionsQuery, input));
            items.push(...response.data.listSelections.items)
            nextToken = response.data.listSelections.nextToken;
            swalCallback(new SwalData('Loading Selections', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}
async function listEnsembleInstruments(ensembleId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { ensembleID: { eq: ensembleId } } }
            const response = await API.graphql(graphqlOperation(listEnsembleInstrumentsQuery, input));
            items.push(...response.data.listEnsembleInstruments.items)
            nextToken = response.data.listEnsembleInstruments.nextToken;
            swalCallback(new SwalData('Loading Ensemble Instruments', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
const listEnsembleInstrumentsQuery = /* GraphQL */ `
    query ListEnsembleInstruments(
        $filter: ModelEnsembleInstrumentsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listEnsembleInstruments(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                ensembleID
            }
            nextToken
        }
    }
`;

const deleteEnsembleInstrumentsMutation = /* GraphQL */ `
    mutation DeleteEnsembleInstruments(
        $input: DeleteEnsembleInstrumentsInput!
        $condition: ModelEnsembleInstrumentsConditionInput
    ) {
        deleteEnsembleInstruments(input: $input, condition: $condition) {
            id
        }
    }
`;

const listSelectionsQuery = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                selectionEnsembleId
            }
            nextToken
        }
    }
`;

const updateSelectionMutation = /* GraphQL */ `
    mutation UpdateSelection(
        $input: UpdateSelectionInput!
        $condition: ModelSelectionConditionInput
    ) {
        updateSelection(input: $input, condition: $condition) {
            id
            selectionEnsembleId
        }
    }
`;

const deleteEnsembleMutation = /* GraphQL */ `
    mutation DeleteEnsemble(
        $input: DeleteEnsembleInput!
        $condition: ModelEnsembleConditionInput
    ) {
        deleteEnsemble(input: $input, condition: $condition) {
            id
        }
    }
`;
