export const getEnsemble = /* GraphQL */ `
    query GetEnsemble($id: ID!) {
        getEnsemble(id: $id) {
            id
            slug
            name
            code
            parts
            chairId
            chair {
                id
                name {
                    first
                    last
                }
            }
            instruments {
                items {
                    id
                    instrument {
                        id
                        name
                    }
                }
            }
            hasParts
            hasAlternates
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;

export const getEnsembleBySlug = /* GraphQL */ `
    query GetEnsembleBySlug(
        $slug: String!
        $sortDirection: ModelSortDirection
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
    ) {
        getEnsembleBySlug(
            slug: $slug
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                slug
                name
                code
                parts
                chairId
                chair {
                    id
                    name {
                        first
                        last
                    }
                }
                instruments {
                    items {
                        id
                        instrument {
                            id
                            name
                        }
                    }
                }
                hasParts
                hasAlternates
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listEnsembleInstruments = /* GraphQL */ `
    query ListEnsembleInstruments(
        $filter: ModelEnsembleInstrumentsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listEnsembleInstruments(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                ensembleID
                instrumentID
                instrument {
                    id
                    name
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const updateEnsemble = /* GraphQL */ `
    mutation UpdateEnsemble(
        $input: UpdateEnsembleInput!
        $condition: ModelEnsembleConditionInput
    ) {
        updateEnsemble(input: $input, condition: $condition) {
            id
            slug
            name
            code
            parts
            chairId
            chair {
                id
                name {
                    first
                    last
                }
            }
            instruments {
                items {
                    id
                    instrument {
                        id
                        name
                    }
                }
                nextToken
            }
            hasParts
            hasAlternates
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;

export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            name {
                first
                last
            }
            ensembleIds
        }
    }
`;

